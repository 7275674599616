<template>
  <div class="my-1">
    <b-button-group>
      <b-button
        v-for="option in checkboxOptions"
        :key="option.value"
        :disabled="isDisabled"
        :class="{'checkbox-tabs-class-active active': selectedTypes.includes(option.value)}"
        class="d-flex align-items-center checkbox-tabs-class"
        @click="toggleSelection(option.value)"
      >
        <b-form-checkbox
          v-model="selectedTypes"
          :value="option.value"
          :disabled="isDisabled"
          :name="`productTypes-${option.value}`"
          class="form-checkbox-class"
          plain
        />
        {{ option.text }}
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
import {
  BFormCheckbox, BButtonGroup, BButton,
} from 'bootstrap-vue'

export default {
  name: 'CatalogTabs',
  components: {
    BFormCheckbox,
    BButtonGroup,
    BButton,
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedTypes: [],
      items: {
        stocks: {
          label: 'Stock',
          route: 'home-main-catalog-list-stocks',
        },
        kits: {
          label: 'Kits',
          route: 'home-main-catalog-list-kits',
        },
        'non-stocks': {
          label: 'Non-Stock',
          route: 'home-main-catalog-list-non-stocks',
        },
      },
    }
  },
  computed: {
    checkboxOptions() {
      return Object.keys(this.items)
        .map(key => ({
          value: key,
          text: this.items[key].label,
        }))
    },
  },
  methods: {
    toggleSelection(value) {
      const index = this.selectedTypes.indexOf(value)
      if (index === -1) {
        this.selectedTypes.push(value)
      } else {
        this.selectedTypes.splice(index, 1)
      }
      this.updateQuery()
    },

    updateQuery() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          types: this.selectedTypes,
        },
      })
        .catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            console.error(err)
          }
        })
      this.$emit('queryChanged', this.selectedTypes)
    },
  },
}
</script>
<style lang="scss" scoped>
  .checkbox-tabs-class {
    background-color: #e6e6e6 !important;
    color: #646464 !important;
    border-color: #E6E6E6 !important;
    box-shadow: none !important;
    margin-right: 9px !important;
    border-radius: 0.358rem !important;
    padding: 0.786rem 1rem !important;
    accent-color: #e6e6e6;
  }
  .checkbox-tabs-class-active {
    border-color: #dde3e7 !important;
    background-color: #646464 !important;
    color: #feffff !important;
  }
  .form-checkbox-class {
    margin-right: 0.2rem !important;
  }
</style>
