export default function config() {
  const ACCESS_ABILITY_CATALOG = { action: 'change', subject: 'Catalog' }
  // Table Handlers
  const tableStockColumns = [
    {
      key: 'batchdelete',
      label: '',
      thStyle: { width: '10px' },
    },
    {
      key: 'calendarIcon',
      label: '',
      thStyle: { width: '92px' },
    },
    {
      key: 'sku',
      label: 'SKU',
      thStyle: { width: '160px' },
      isSortable: true,
    },
    // {
    //   key: 'icon',
    //   label: '',
    // },
    {
      key: 'name',
      label: 'Name',
      isSortable: true,
    },
    // {
    //   key: 'brand',
    //   label: 'Brand',
    //   isSortable: true,
    // },
    // {
    //   key: 'model',
    //   label: 'Model',
    //   thStyle: { width: '200px' },
    //   isSortable: true,
    // },
    // {
    //   key: 'category',
    //   label: 'Category',
    //   isSortable: true,
    // },
    // {
    //   key: 'assets',
    //   label: 'Assets',
    //   thStyle: { width: '90px', textAlign: 'center' },
    //   isSortable: true,
    // },
    {
      key: 'is_active',
      label: 'Status',
      thStyle: { width: '100px' },
      isSortable: true,
    },
  ]
  const tableNonStockColumns = [
    {
      key: 'batchdelete',
      label: '',
      thStyle: { width: '10px' },
    },
    {
      key: 'sku',
      label: 'SKU',
      isSortable: true,
      thStyle: { width: '100px' },
    },
    {
      key: 'name',
      isSortable: true,
      label: 'Name',
    },
    // {
    //   key: 'assets',
    //   label: 'Assets',
    //   thStyle: { width: '90px', textAlign: 'center' },
    //   isSortable: true,
    // },
    {
      key: 'is_active',
      label: 'Status',
    },
  ]
  const trashTableColumns = [
    {
      key: 'action',
      label: '',
      thStyle: { width: '100px' },
    },
    {
      key: 'sku',
      label: 'SKU',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'brand',
      label: 'Brand',
    },
    {
      key: 'model',
      label: 'Model',
    },
    {
      key: 'is_active',
      label: 'Status',
    },
  ]
  return {
    tableStockColumns,
    tableNonStockColumns,
    ACCESS_ABILITY_CATALOG,
    trashTableColumns,
  }
}
