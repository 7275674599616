var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('portal',{attrs:{"to":"tab-title"}},[_c('div',[_c('h4',{staticClass:"text-center font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('Catalog'))+" ")])])]),_c('checkbox-tabs',{attrs:{"is-disabled":this.$refs.lTableRef ? this.$refs.lTableRef.isBusy : false},on:{"queryChanged":_vm.fetchTableData}}),_c('l-table',{ref:"lTableRef",attrs:{"table-config-options":_vm.getTableConfigOptions(),"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableStockColumns,"is-delete":true,"filter-class":new _vm.Products(),"has-action":true,"create-page-url":{ name: _vm.createBtnUrl },"create-btn-dashed":true,"is-suggestion-table":true,"trash-list-path":"home-main-catalog-trash-list-stocks","can-create":_vm.ACCESS_ABILITY_CATALOG,"can-show-trash-list":_vm.ACCESS_ABILITY_CATALOG,"action-name":"changeStatus","query-params":_vm.getQueryParams(),"create-btn-disabled":_vm.selectedTypes.length > 1,"confirm-notification-options":_vm.getConfirmNotificationOptions()},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var data = ref.data;
return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"name-cell cursor-pointer",attrs:{"title":_vm.getValue(data, 'name') ? _vm.getValue(data, 'name') : '—'}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],style:({ display: _vm.checkForCat(data.item.type) ? 'none' : '' }),attrs:{"icon":"LKitsIcon","title":"View the kit","size":"26"},on:{"click":function($event){return _vm.goToPage(data.item)}}}),_c('feather-icon',{staticClass:"mr-1",style:({ display: _vm.checkForCat(data.item.type) ? '' : 'none' }),attrs:{"icon":"LNonStockIcon","size":"26"}}),_c('span',{on:{"click":function($event){return _vm.goToPage(data.item)}}},[_vm._v(" "+_vm._s(_vm.getValue(data, 'name') ? _vm.getValue(data, 'name') : '—')+" ")])],1)]}},{key:"cell(sku)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.goToPage(data.item)}}},[_vm._v(" "+_vm._s(data.item.sku)+" ")])]}},{key:"cell(icon)",fn:function(ref){
var data = ref.data;
return [_c('feather-icon',{staticClass:"cursor-pointer defaultIconColor",attrs:{"icon":_vm.renderIcon(data.item),"size":"26"}})]}},{key:"cell(is_active)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.is_active === null ? '—' : data.item.is_active ? 'Active' : 'Inactive')+" ")]}},{key:"cell(calendarIcon)",fn:function(ref){
var data = ref.data;
return [_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],class:{ 'disabled-icon': !_vm.checkForCat(data.item.type) },style:({ cursor: _vm.checkForCat(data.item.type) ? 'pointer' : 'not-allowed' }),attrs:{"icon":"LStockCalendarIcon","title":"Calendar","size":"32"},on:{"click":function($event){!_vm.checkForCat(data.item.type) ? null : _vm.navigateToCalendar(data.item.id, $event)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"ml-1 cursor-pointer",attrs:{"icon":"LStockEyeIcon","size":"32","title":_vm.$t('View')},on:{"click":function($event){return _vm.goToPage(data.item)}}})]}},{key:"table-top-right-side-extras-prev",fn:function(ref){
var data = ref.data;
return [_c('actions',{attrs:{"icon-name":"LExportIcon","title":"Export","b-drop-down-items":_vm.exportButtonItems},on:{"actionHandler":_vm.exportActions}}),_c('actions',{attrs:{"icon-name":"LImportIcon","title":"Import","b-drop-down-items":_vm.exportButtonItems},on:{"actionHandler":_vm.importActions}})]}}])}),_c('export-c-s-v',{ref:"export-c-s-v",attrs:{"module-name":_vm.MODULE_NAME}}),_c('import-c-s-v',{ref:"import-c-s-v",attrs:{"module-name":_vm.MODULE_NAME},on:{"refetchTable":_vm.refetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }