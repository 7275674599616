var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('l-table',{ref:"lTableRef",attrs:{"table-config-options":_vm.getTableConfigOptions(),"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumns,"is-delete":true,"filter-class":new _vm.Products(),"row-clicked":"home-catalog-view","has-action":true,"create-page-url":{ name: 'home-catalog-create' },"is-suggestion-table":true,"trash-list-path":this.productType === this.productNonStocks ? 'home-main-catalog-trash-list-non-stocks' : 'home-main-catalog-trash-list-stocks',"can-create":_vm.ACCESS_ABILITY_CATALOG,"can-show-trash-list":_vm.ACCESS_ABILITY_CATALOG,"action-name":"changeStatus","query-params":_vm.getQueryParams(),"confirm-notification-options":_vm.getConfirmNotificationOptions()},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var data = ref.data;
return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"name-cell",attrs:{"title":_vm.getValue(data, 'name') ? _vm.getValue(data, 'name') : '—'}},[_vm._v(" "+_vm._s(_vm.getValue(data, 'name') ? _vm.getValue(data, 'name') : '—')+" ")])]}},{key:"cell(icon)",fn:function(ref){
var data = ref.data;
return [_c('feather-icon',{staticClass:"cursor-pointer defaultIconColor",staticStyle:{"margin-bottom":"5px"},attrs:{"icon":_vm.renderIcon(data.item),"size":"24"}})]}},{key:"cell(brand)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.getValue(data, 'brand') ? _vm.getValue(data, 'brand').name : '—')+" ")]}},{key:"cell(category)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.getValue(data, 'categories') ? _vm.getValue(data, 'categories').map(function (category) { return category.name; }).join(' , ') : '—')+" ")]}},{key:"cell(model)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.getValue(data, 'detail').model ? _vm.getValue(data, 'detail').model : '—')+" ")]}},{key:"cell(status)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.status === null ? '—' : data.item.status ? 'Active' : 'Inactive')+" ")]}},{key:"cell(calendarIcon)",fn:function(){return [_c('feather-icon',{staticClass:"feather-icon border-dotted cursor-pointer defaultIconColor",staticStyle:{"padding":"4px"},attrs:{"icon":"LCalendarsIcon","size":"30"}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"lightIcon cursor-pointer border-dotted featherIcon ml-1",staticStyle:{"padding":"4px"},attrs:{"icon":"LEyeIcon","size":"30","title":_vm.$t('View')}})]},proxy:true},{key:"table-top-right-side-extras-prev",fn:function(ref){
var data = ref.data;
return [_c('actions',{attrs:{"icon-name":"LImportIcon","title":"Import","b-drop-down-items":_vm.exportButtonItems},on:{"actionHandler":_vm.importActions}}),_c('actions',{attrs:{"icon-name":"LExportIcon","title":"Export","b-drop-down-items":_vm.exportButtonItems},on:{"actionHandler":_vm.exportActions}})]}}])}),_c('export-c-s-v',{ref:"export-c-s-v",attrs:{"module-name":_vm.MODULE_NAME}}),_c('import-c-s-v',{ref:"import-c-s-v",attrs:{"module-name":_vm.MODULE_NAME},on:{"refetchTable":_vm.refetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }