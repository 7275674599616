<template>
  <div>
    <l-table
      ref="lTableRef"
      :table-config-options="getTableConfigOptions()"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :is-delete="true"
      :filter-class="new Products()"
      row-clicked="home-catalog-view"
      :has-action="true"
      :create-page-url="{ name: 'home-catalog-create' }"
      :is-suggestion-table="true"
      :trash-list-path="this.productType === this.productNonStocks ? 'home-main-catalog-trash-list-non-stocks' : 'home-main-catalog-trash-list-stocks'"
      :can-create="ACCESS_ABILITY_CATALOG"
      :can-show-trash-list="ACCESS_ABILITY_CATALOG"
      action-name="changeStatus"
      :query-params="getQueryParams()"
      :confirm-notification-options="getConfirmNotificationOptions()"
    >
      <template #cell(name)="{ data }">
        <div
          v-b-tooltip.noninteractive.hover
          class="name-cell"
          :title="getValue(data, 'name') ? getValue(data, 'name') : '—'"
        >
          {{ getValue(data, 'name') ? getValue(data, 'name') : '—' }}
        </div>
      </template>
      <template #cell(icon)="{ data }">
        <feather-icon
          :icon="renderIcon(data.item)"
          size="24"
          style="margin-bottom: 5px"
          class="cursor-pointer defaultIconColor"
        />
      </template>
      <template #cell(brand)="{ data }">
        {{ getValue(data, 'brand') ? getValue(data, 'brand').name : '—' }}
      </template>
      <template #cell(category)="{ data }">
        {{ getValue(data, 'categories') ? getValue(data, 'categories').map(category => category.name).join(' , ') : '—' }}
      </template>
      <template #cell(model)="{ data }">
        {{ getValue(data, 'detail').model ? getValue(data, 'detail').model : '—' }}
      </template>
      <template #cell(status)="{ data }">
        {{ data.item.status === null ? '—' : data.item.status ? 'Active' : 'Inactive' }}
      </template>
      <template #cell(calendarIcon)>
        <feather-icon
          icon="LCalendarsIcon"
          size="30"
          style="padding: 4px"
          class="feather-icon border-dotted cursor-pointer defaultIconColor"
        />
        <feather-icon
          v-b-tooltip.noninteractive.hover.bottom
          icon="LEyeIcon"
          class="lightIcon cursor-pointer border-dotted featherIcon ml-1"
          size="30"
          style="padding: 4px"
          :title="$t('View')"
        />
      </template>
      <template #table-top-right-side-extras-prev="{ data }">
        <actions
          icon-name="LImportIcon"
          title="Import"
          :b-drop-down-items="exportButtonItems"
          @actionHandler="importActions"
        />
        <actions
          icon-name="LExportIcon"
          title="Export"
          :b-drop-down-items="exportButtonItems"
          @actionHandler="exportActions"
        />
      </template>
    </l-table>
    <export-c-s-v
      ref="export-c-s-v"
      :module-name="MODULE_NAME"
    />
    <import-c-s-v
      ref="import-c-s-v"
      :module-name="MODULE_NAME"
      @refetchTable="refetchData"
    />
  </div>
</template>

<script>

import LTable from '@/views/components/LTable/LTable.vue'
// eslint-disable-next-line import/no-cycle
import Products from '@/views/components/filters/fields/Products'
import {
  CATALOG_TYPE_COMPONENT,
  CATALOG_TYPE_CONTAINER,
  CATALOG_TYPE_NON_STOCK,
  CATALOG_TYPE_PRODUCT,
} from '@/views/main/catalog/catalogType'
import { mapState } from 'vuex'
import { VBTooltip } from 'bootstrap-vue'
import ExportCSV from '@/views/components/Modals/ExportCSV.vue'
import ImportCSV from '@/views/components/Modals/ImportCSV.vue'
import config from '../catalogConfig'
import Actions from '../../../components/Actions.vue'

export default {
  name: 'ListComponent',
  components: {
    LTable,
    ExportCSV,
    ImportCSV,
    Actions,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    productType: {
      type: String,
      required: true,
    },
  },
  computed: {
    Products() {
      return Products
    },
    ...mapState('listModule', ['productNonStocks']),
    exportButtonItems() {
      return this.$store.state[this.MODULE_NAME].exportButtonItems
    },
  },
  created() {
    this.$store.commit(`${this.MODULE_NAME}/GET`, {
      type_id: this.productType === this.productNonStocks ? CATALOG_TYPE_NON_STOCK : CATALOG_TYPE_PRODUCT,
    })
  },
  methods: {
    getQueryParams() {
      return {
        status: false,
      }
    },
    getConfirmNotificationOptions() {
      return { text: 'It is possible to revert this', confirmButtonText: 'Yes, deactivate it' }
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    renderIcon(data) {
      let iconP
      switch (data.type_id) {
        case CATALOG_TYPE_COMPONENT:
          iconP = 'LComponentIcon'
          break
        case CATALOG_TYPE_CONTAINER:
          iconP = 'LContainerIcon'
          break
        default:
          iconP = ''
      }
      return iconP
    },
    getValue(data, tableColumnName) {
      return data.item[tableColumnName] ?? {}
    },
    getTableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/${this.productType}`,
      }
    },
    exportActions({ btnTitle, actionType }) {
      this.$store.commit(`${this.MODULE_NAME}/SET_EXPORT`, {
        title: btnTitle,
        actionType,
        query: this.$refs.lTableRef.totalQuery,
      })
      this.$refs['export-c-s-v'].show()
    },
    importActions({ btnTitle, actionType }) {
      this.$store.commit(`${this.MODULE_NAME}/SET_IMPORT`, {
        title: btnTitle,
        actionType,
        query: this.$refs.lTableRef.totalQuery,
      })
      this.$refs['import-c-s-v'].show()
    },
  },
  setup() {
    const MODULE_NAME = 'catalogs'
    const { tableColumns, ACCESS_ABILITY_CATALOG } = config()
    return {
      MODULE_NAME,
      tableColumns,
      ACCESS_ABILITY_CATALOG,
    }
  },
}
</script>
